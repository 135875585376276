import React from "react"
import Layout from "../Layout"
import Contact from "../Contact"
import Partners from "../Partners"
import Gallery from "../Gallery"
import Hero from "../Hero"
import Numbers from "../Numbers"
import Summary2022 from "../Summary2022"

const IndexPage = ({ lang }) => (
  <Layout lang={lang}>
    <Hero lang={lang} type="hero2022" />
    <Numbers lang={lang} type="summary2022" />
    <Gallery lang={lang} />
    <Summary2022 lang={lang} />
    <Contact lang={lang} />
  </Layout>
)

export default IndexPage
